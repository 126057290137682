import './sentry_instruments.js';
import { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { logBEVersion, logInfo } from 'utils/logging';
import { _ISNOTPRODUCTION, brandName } from 'utils/functions';
import Routes from './Routes';
import ThemeStyles from 'ThemeStyles';
import brandBuild from 'assets/file/brandBuild.json';
import { clearLocalStorage } from 'localstorage/setter';
import { clearSessionStorage } from 'sessionstorage/setter';
import IframeProvider from 'context/IframeContext';
import ContentProvider from './context/ContentContext';
import { getLSBEVersion } from 'localstorage/getter';

const brandRelease = `BackOffice_Login_${import.meta.env.MODE.toUpperCase()}@${
  brandBuild.version
}`;

logInfo(`MODE : ${import.meta.env.MODE.toUpperCase()}`);
logInfo(`SENTRY BUILD INFO : ${brandRelease}`);

const resetShortcut = (event) => {
  if (event.key === 'X' && event.ctrlKey && event.shiftKey && event.altKey) {
    console.log('RESETTING BACKOFFICE');
    clearLocalStorage();
    clearSessionStorage();
    window.location.href = '/login';
  }
};

function App() {
  useEffect(() => {
    if (_ISNOTPRODUCTION()) {
      document.title = `${import.meta.env.VITE_TITLE} - ${brandName(true)}`;
      document.addEventListener('keydown', resetShortcut);
    } else {
      document.title = `${brandName(true)}`;
    }

    // LOGGING BE VERSION
    const be_ver = getLSBEVersion();
    logBEVersion(be_ver);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <ContentProvider useSuspense={false}>
          <IframeProvider useSuspense={false}>
            <Router>
              <Routes />
            </Router>
          </IframeProvider>
        </ContentProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
